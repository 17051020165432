import React, { useState } from "react";
import contactImg from "../assets/img/contact-img.svg";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { Collapse } from "bootstrap";

const Contact = () => {
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    phone: "",
  };
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});
  const [formDetails, setFormDetails] = useState(formInitialDetails);

  const onFormUpdate = (cat, val) => {
    setFormDetails({ ...formDetails, [cat]: val });
  };
  const handleSubmit = async (e) => {
    console.log(formDetails);
    e.preventDefault();
    setButtonText("Sending...");
    let res = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "Application/json; charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    try {
      let data = await res.json();
      setFormDetails(formInitialDetails);
      if (data?.code === 200) {
        setStatus({ success: true, message: "Message sent successfully." });
        setButtonText("Send ");
      } else {
        setStatus({
          success: false,
          message: "Something went wrong. Please try again later.",
        });
        setButtonText("Send");
      }
    } catch {
      setStatus({
        success: false,
        message: "Something went wrong. Please try again later.",
      });
      setButtonText("Send");
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <img src={contactImg} alt="Contact Us"></img>
            <Col md={6}>
              <h2>Get In Touch</h2>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={6} className={"px-1"}>
                    <input
                      type={"text"}
                      value={formDetails?.firstName}
                      placeholder={"First Name"}
                      onChange={(e) =>
                        onFormUpdate("firstName", e.target.value)
                      }
                    ></input>
                  </Col>
                  <Col sm={6} className={"px-1"}>
                    <input
                      type={"text"}
                      value={formDetails?.lastName}
                      placeholder={"Last Name"}
                      onChange={(e) => onFormUpdate("lastName", e.target.value)}
                    ></input>
                  </Col>
                  <Col sm={6} className={"px-1"}>
                    <input
                      type={"email"}
                      value={formDetails?.email}
                      placeholder={"Email"}
                      onChange={(e) => onFormUpdate("email", e.target.value)}
                    ></input>
                  </Col>
                  <Col sm={6} className={"px-1"}>
                    <input
                      type={"phone"}
                      value={formDetails?.phone}
                      placeholder={"Phone"}
                      onChange={(e) => onFormUpdate("phone", e.target.value)}
                    ></input>
                  </Col>
                  <Col className={"px-1"}>
                    <textarea
                      row={"6"}
                      type={"phone"}
                      value={formDetails.message}
                      placeholder={"Message"}
                      onChange={(e) => onFormUpdate("message", e.target.value)}
                    ></textarea>
                    <button type="submit">
                      <span>{buttonText}</span>
                    </button>
                  </Col>
                  {status.message && (
                    <Col>
                      <p
                        className={
                          status.success === false ? "danger" : "success"
                        }
                      >
                        {status.message}
                      </p>
                    </Col>
                  )}
                </Row>
              </form>
            </Col>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
