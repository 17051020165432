import React, { useEffect, useState } from "react";
import { Row, Col, Alert } from "react-bootstrap";

const NewsLetter = ({ onValidated, status, message }) => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    if (status === "success") {
      clearFields();
    }
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      email.indexOf(".") > -1 &&
      onValidated({ EMAIL: email });
  };
  const clearFields = () => {
    setEmail("");
  };
  return (
    <Col lg={12}>
      <div className="newsletter-bx"></div>
      <Row>
        <Col lg={12} md={6} xl={5}>
          <h3>Subscribe to our Newsletter</h3>
          {status === "sending" && <Alert>Sending</Alert>}
          {status === "error" && <Alert variant="danger">{message}</Alert>}
          {status === "success" && <Alert variant="success">{message}</Alert>}
        </Col>
        <Col md={6} xl={7}>
          <form onSubmit={handleSubmit}>
            <div className="new-email-bx">
              <input
                value={email}
                type="email"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              ></input>
              <button type="submit" onClick={() => handleSubmit}>
                Submit
              </button>
            </div>
            {submitted && (
              <Col>
                <p className={"success"}>
                  You have successfully subscribed to our newsletter
                </p>
              </Col>
            )}
          </form>
        </Col>
      </Row>
    </Col>
  );
};

export default NewsLetter;
