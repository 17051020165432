import MailchimpSubscribe from "react-mailchimp-subscribe";
import NewsLetter from "./NewsLetter";

const MailchimpForm = () => {
  const postUrl =
    "https://gmail.us14.list-manage.com/subscribe/post?u=61ab127c24810fb42e8681aef&id=acbdb774b0";

  return (
    <>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <NewsLetter
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          ></NewsLetter>
        )}
      ></MailchimpSubscribe>
    </>
  );
};

export default MailchimpForm;
